/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { FaSave, FaTrashAlt, FaShareAlt } from 'react-icons/fa';
import { auth } from './FirebaseConfig';

function ListOptions(props) {
    return (
        <div id='list-options'>
            { !props.guestList ? 
            <div className='options'>
                <button id='save-list' onClick={() => props.saveList()}>Save <FaSave /></button>
                <button id='share-list' onClick={() => props.shareList()}>Share <FaShareAlt /></button>
                { auth.currentUser.uid === props.userID ?<button id='delete-list' onClick={() => props.deleteList()}>Delete <FaTrashAlt /></button> : null }
            </div> : null }
            <div id='unsaved-placeholder'>
                { (props.unsavedChanges || props.guestList) ? <span id='unsaved-changes'>*Unsaved changes</span> : null }
            </div>
        </div>
    );
}

export { ListOptions };
