import { initializeApp } from 'firebase/app';
import { getFunctions } from 'firebase/functions';
import { getDatabase } from 'firebase/database';
import { getAuth } from 'firebase/auth';


// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyCHWvlCyN2faquCXFPdbmCGttUKPdEegSs",
    authDomain: "christmas-list-maker.firebaseapp.com",
    projectId: "christmas-list-maker",
    storageBucket: "christmas-list-maker.appspot.com",
    messagingSenderId: "507680352573",
    appId: "1:507680352573:web:649241a196963c1e1a3d54"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

const database = getDatabase(app);
const auth = getAuth(app);
const functions = getFunctions();

export { database, auth, functions };