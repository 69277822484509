import React from 'react';
import { database, auth, functions } from './FirebaseConfig';
import { ref, child, get } from 'firebase/database';
import { arrayMoveImmutable } from 'array-move';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { ListOptions } from './ListOptions';
import { ListItem } from './ListItem';
import { StatusComponent } from './StatusComponent';
import { NewItemForm } from './NewItemForm';
import TextareaAutosize from 'react-textarea-autosize';
import { httpsCallable } from 'firebase/functions';
import { Loading } from './Loading';

class List extends React.Component {
    constructor (props) {
        super(props);
        this.state = {
            listName: '',
            recentSavedListName: '',
            listItems: [],
            recentSavedItems: [],
            loaded: false, //if the page has loaded
            loading: false, //if an action is loading
            showMessage: false,
            saveSuccessful: true,
            saveMessage: 'List saved!',
            showModal: false
        }
        this.handleOnDragEnd = this.handleOnDragEnd.bind(this);
        this.addNewItem = this.addNewItem.bind(this);
        this.deleteItem = this.deleteItem.bind(this);
        this.deleteList = this.deleteList.bind(this);
        this.saveList = this.saveList.bind(this);
        this.changeMessage = this.changeMessage.bind(this);
        this.updateListState = this.updateListState.bind(this);
        this.updateListItemText = this.updateListItemText.bind(this);
        this.shareList = this.shareList.bind(this);
        this.openModal = this.openModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
    }

    componentDidMount() {
        if (this.props.guestList) {
            let list = JSON.parse(sessionStorage.getItem('list'));
            let listName = sessionStorage.getItem('listName');

            if ((list == null || list === '') || (listName == null || listName === '')) {
                list = ['Example item'];
                listName = 'Example list';
            }

            sessionStorage.setItem('list', JSON.stringify(list));
            sessionStorage.setItem('listName', listName);

            this.setState({
                listItems: list,
                recentSavedItems: list,
                listName: listName,
                recentSavedListName: listName,
                loaded: true
            });
        } else {
            get(child(ref(database), this.props.userID + '/' + this.props.listID)).then((snapshot) => {
                const val = snapshot.val();

                if (val == null) {
                    this.setState({
                        listName: null,
                        loaded: true
                    });
                } else {
                    if (val['list'] != null) {
                        this.setState({
                            listItems: val['list'],
                            recentSavedItems: val['list']
                        });
                    }
                    this.setState({
                        listName: val['name'],
                        recentSavedListName: val['name'],
                        loaded: true
                    });
                }
            });    
        }
    }

    //Needed for Draggable
    handleOnDragEnd(result) {
        if (!result.destination) return;
        const newArr = arrayMoveImmutable(this.state.listItems, result.source.index, result.destination.index);
        this.updateListState(newArr);
    }

    updateListItemText(index, text, link) {
        let arr = this.state.listItems.slice();
        arr[index] = {text: text, link: link};
        this.updateListState(arr);
    }

    //Push the new item to the end of the list
    addNewItem() {
        let newArr = this.state.listItems.slice();
        newArr.push({item: '', link: ''});
        this.updateListState(newArr);
    }

    //Remove an item with the given name
    deleteItem(itemName) {
        let newArr = this.state.listItems.slice();
        let index;
        for (let i = 0; i < newArr.length; i++) {
            let curItem = newArr[i];
            if (curItem.text === itemName) {
                index = i;
                break;
            }
        }
        newArr.splice(index, 1);
        this.updateListState(newArr);
    }

    //Deletes the entire list after confirming with the user
    deleteList() {
        if (window.confirm('Are you sure you want to delete this list?')) {
            this.setState({
                loading: true
            })

            const del = httpsCallable(functions, 'deleteList');

            del({listID: this.props.listID}).then(() => {
                this.props.navToAllLists();
            });
        }
    }

    //After updating the state, check if there are unsaved changes based on the new list
    updateListState(newArr) {
        this.setState({
            listItems: newArr,
        }, () => {
            this.props.setUnsavedChanges(!this.checkIfSavedListEqualsCurrentList());
        });
        if (this.props.guestList) sessionStorage.setItem('list', JSON.stringify(newArr));
    }

    //Saves the new list to the database and updates the current 'saved list' state
    saveList() {
        let save = httpsCallable(functions, 'saveList');

        this.setState({
            loading: true
        });

        save({userID: this.props.userID, listID: this.props.listID, list: this.state.listItems, name: this.state.listName}).then(() => {
            const arr = this.state.listItems.slice();

            this.changeMessage(true);
            this.props.setUnsavedChanges(false);
            this.setState({
                recentSavedItems: arr,
                recentSavedListName: this.state.listName,
                loading: false
            });
        }).catch(() => {
            this.changeMessage(false);
        });
    }

    shareList() {
        if (navigator.canShare) {
            navigator.share({
                url: window.location.href,
            });
        } else {
            navigator.clipboard.writeText(window.location.href).then(() => {
                this.setState({
                    saveMessage: 'URL Copied to clipboard'
                }, () => {
                    this.changeMessage(true);
                });
            });
        }
    }

    //Display a message based on the success of the save to the database
    changeMessage(success) {
        this.setState({
            showMessage: true,
            saveSuccessful: success,
            loading: false
        });
        setTimeout(() => {
            this.setState({
                saveMessage: 'List saved!',
                showMessage: false
            });
        }, 2000); //Displays the message for 2 seconds
    }

    //Compares the current list along with the 'saved' list to see if they are the same
    checkIfSavedListEqualsCurrentList() {
        const a = this.state.listItems;
        const b = this.state.recentSavedItems;

        if (a === b) return true;
        if (a == null || b == null) return false;
        if (a.length !== b.length) return false;

        for (let i = 0; i < a.length; ++i) {
            if (a[i] !== b[i]) return false;
        }
        return true;
    }

    checkIfSavedNameEqualsCurrentName() {
        return this.state.listName === this.state.recentSavedListName;
    }

    openModal() {
        this.setState({showModal: true});
    }

    closeModal() {
        this.setState({showModal: false});
    }

    render() {
        if (!this.state.loaded) { //Display loading message until database is loaded
            return (
                <Loading />
            );
        } else if (this.state.listName != null) { //Display list on successful load
            const fullList = this.state.listItems.map((item, index) => <ListItem 
                                                                        key={item.text} 
                                                                        index={index} 
                                                                        itemName={item.text}
                                                                        itemLink={item.link}
                                                                        userID={this.props.userID} 
                                                                        deleteItem={this.deleteItem} 
                                                                        updateListItemText={this.updateListItemText}
                                                                        />);

            //Checks if the user is looking at their own list, or at someone else's list
            if ((auth.currentUser && this.props.userID === auth.currentUser.uid)) { 
                let l = <Droppable droppableId='items'>
                    {(provided) => (
                        <ul id='list' {...provided.droppableProps} ref={provided.innerRef}>
                            {fullList}
                            {provided.placeholder}
                        </ul>
                    )}
                </Droppable>
                

                //If it is the user's list, display the list along with editing options
                return (
                    <div>
                        <TextareaAutosize type='text' className='list-name' onChange={(e) => {
                            this.setState({listName: e.target.value}, () => {
                                this.props.setUnsavedChanges(!this.checkIfSavedNameEqualsCurrentName());
                                if (this.props.guestList) sessionStorage.setItem('listName', e.target.value);
                            }); 
                        }} onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                e.target.blur();
                            }
                        }} value={this.state.listName} />
                        <hr />
                        <ListOptions 
                            openModal={this.openModal} 
                            guestList={this.props.guestList} 
                            deleteList={this.deleteList} 
                            createFile={this.createFile} 
                            saveList={this.saveList} 
                            shareList={this.shareList} 
                            unsavedChanges={this.props.unsavedChanges} 
                            changeListType={this.changeListType} 
                            userID={this.props.userID}
                        />
                        < hr />
                        {this.state.showMessage ? <StatusComponent success={this.state.saveSuccessful} successMessage={this.state.saveMessage} failureMessage='Save unsuccessful' /> : null }
                        <NewItemForm addNewItem={this.addNewItem} />
                        <DragDropContext onDragEnd={this.handleOnDragEnd}>
                            {l}
                        </DragDropContext>
                        {this.state.loading ? <Loading /> : null}

                    </div>
                );
            } else {
                return (
                    <div>
                        <h3 className='list-name'>{this.state.listName}</h3>
                        <hr />
                        <ul id='list'>
                            {fullList}
                        </ul>
                    </div>

                );
            }
        } else {
            //If no list is found, display error
            return (
                <h3>No list found for this link</h3>
            );
        }
    }
}

export { List }
