import React from 'react';
import { FaPlus } from 'react-icons/fa';

class NewItemForm extends React.Component {
    constructor(props) {
        super(props);

        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleSubmit(e) {
        e.preventDefault();
        this.props.addNewItem();
    }

    render() {
        return (
            <form id='new-item-form' onSubmit={this.handleSubmit}>
                <button type='submit'>Add <FaPlus /></button>
            </form>
        );
    }
}

export { NewItemForm };
